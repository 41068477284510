import React, {Fragment} from "react"
import ContentLoader from "react-content-loader" 
import { Grid, Hidden } from '@material-ui/core'
import config from "../../../config/config.json";
import Image from './../Image'
import RawMarkup from './../RawMarkup'

//import CircularProgress from '@material-ui/core/CircularProgress';

export default function Preload(props) {

    const preload = props.preload
    /* console.log('preload:', preload) */

    const GetTitle = () => {
        if (preload.title && preload.title !== '') {
            return (<h2 className="title">{preload.title}</h2>)
        } else {
            return (<Fragment></Fragment>)
        }
    }

    const GetDescription = () => {
        if (preload.description && preload.description !== '') {
            return (<p className="description"><RawMarkup content={preload.description} /></p>)
        } else {
            return (<Fragment></Fragment>)
        }
    }

    const GetImage = () => {
        if (preload.image && preload.image !== '') {
            return (<img src={preload.image} alt={config.client.nameCopy} />)
        } else {
            return (<Image img={`${config.client.name}/preload-booking.gif`} alt={config.client.nameCopy} />)
            //return (<CircularProgress />)
        }
    }

    return (
        <Fragment>
            <div className="wrapper preload preload-common Preload">
                <div className="container-preload-image">
                    <div className="inner-preload-image">
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <div className="text-center">
                                    <GetImage />
                                    <GetTitle />
                                    <GetDescription />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <div className="preload-header">
                    <Grid container className="MuiToolbar-gutters">
                        <Image img={`${config.client.name}/logo.png`} alt={config.client.nameCopy} className="logo" />
                    </Grid>
                </div>
                <div className="main">
                    <Grid container spacing={2}>
                        <Grid item md={8} className="col_content">
                            {/* MOBILE CONTENT */}
                            <Hidden mdUp>
                                <div className="responsive">  
                                    <ContentLoader title="" speed={2} backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                        width={360} 
                                        height={50} 
                                        viewBox="0 0 360 50" >
                                        <circle cx="20" cy="20" r="20" /> 
                                        <rect x="50" y="5" rx="15" ry="15" width="250" height="30" />
                                    </ContentLoader>

                                    <ContentLoader title="" speed={2} backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                        width={385}
                                        height={200}
                                        viewBox="0 0 385 200">
                                        <rect x="0" y="0" rx="10" ry="10" width="385" height="200" />
                                    </ContentLoader>
                                </div>
                            </Hidden>
                            {/* end MOBILE CONTENT */}

                            <Hidden smDown>
                                <div className="responsive">
                                    <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                        width={385}
                                        height={55}
                                        viewBox="0 0 385 55">
                                        <rect x="0" y="0" rx="5" ry="5" width="385" height="45" />
                                    </ContentLoader>
                                </div>

                                <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                    width={450} 
                                    height={50} 
                                    viewBox="0 0 450 50" >
                                    <circle cx="20" cy="20" r="20" /> 
                                    <rect x="50" y="5" rx="15" ry="15" width="395" height="30" />
                                </ContentLoader>

                                <div className="responsive">
                                    <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                        width={385}
                                        height={85}
                                        viewBox="0 0 385 85">
                                        <rect x="0" y="0" rx="5" ry="5" width="385" height="70" />
                                    </ContentLoader>
                                </div>

                                <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                    width={450} 
                                    height={50} 
                                    viewBox="0 0 450 50" >
                                    <rect x="0" y="0" rx="13" ry="13" width="395" height="26" />
                                </ContentLoader>

                                {/* <div className="responsive">
                                    <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                        width={200}
                                        height={40}
                                        viewBox="0 0 200 40">
                                        <rect x="0" y="0" rx="3" ry="3" width="200" height="40" />
                                    </ContentLoader>
                                </div> */}
                            </Hidden>




                            
                            
                        </Grid>
                        <Hidden smDown>
                            <Grid item md={3} className="col_sidebar">
                                <div className="responsive">
                                    {/* <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                        width={200}
                                        height={40}
                                        viewBox="0 0 200 40">
                                        <rect x="0" y="0" rx="5" ry="5" width="200" height="30" />
                                    </ContentLoader> */}
                                    
                                    <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                        width={200}
                                        height={100}
                                        viewBox="0 0 200 100">
                                        <rect x="0" y="0" rx="5" ry="5" width="200" height="90" />
                                    </ContentLoader>

                                    <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                        width={200}
                                        height={60}
                                        viewBox="0 0 200 60">
                                        <rect x="0" y="0" rx="5" ry="5" width="200" height="60" />
                                    </ContentLoader>
                                </div>
                            </Grid>
                        </Hidden>
                    </Grid>
                </div>
                <div className="preload-footer"></div>
            </div>
        </Fragment>
    )
}
