import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Grid, Container, Hidden } from '@material-ui/core'
import Certificates from "./layout/Certificates";

export default function Footer(props) {
    const {client} = props
    return (
        <footer >
            <Hidden mdUp> 
                <Container>
                    <Grid container>
                        <Grid item md={5}>
                            <Certificates client={client} />                     
                        </Grid>
                        <Grid item md={7}>
                            {client.copyright.map((item, i) => <Typography key={i} component="p">{item.item}</Typography>)}                        
                        </Grid>
                    </Grid>
                </Container>
            </Hidden>
        </footer>
    )
}
