import React, {Fragment} from "react"
//import ContentLoader from "react-content-loader" 
import { Grid } from '@material-ui/core'
//import CircularProgress from '@material-ui/core/CircularProgress';
import config from "../../../config/config.json";
import Image from './../Image'
import RawMarkup from './../RawMarkup'

export default function PreloadStepFull(props) {

    const preload = props.preload

    /* console.log('PreloadStepFull:', preload) */

    const GetTitle = () => {
        if (preload.title && preload.title !== '') {
            return (<h2 className="title">{preload.title}</h2>)
        } else {
            return (<Fragment></Fragment>)
        }
    }

    const GetDescription = () => {
        if (preload.description && preload.description !== '') {
            return (<p className="description"><RawMarkup content={preload.description} /></p>)
        } else {
            return (<Fragment></Fragment>)
        }
    }

    const GetImage = () => {
        if (preload.image && preload.image !== '') {
            return (<img src={preload.image} alt={config.client.nameCopy} />)
        } else {
            return (<Image img={`${config.client.name}/preload-booking.gif`} alt={config.client.nameCopy} />)
            //return (<CircularProgress />)
        }
    }
    return (
        <Fragment>
            <div className="preload preload-common PreloadStepFull">
                <div className="container-preload-image">
                    <div className="inner-preload-image">
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <div className="text-center">
                                    <GetImage />
                                    <GetTitle />
                                    <GetDescription />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
