//PADRE CardDetail
import React, {Fragment} from 'react'
import CardOneCol from './CardOneCol'
import CardOneColV2 from './CardOneColV2'
import CardTwoCols from './CardTwoCols'
import CardTwoColsV2 from './CardTwoColsV2'
import CardOneColV3 from './CardOneColV3'

export default function DetailItems(props) {
    /* console.log('DetailItems', props) */
    const {item, template, showDetailSearch, showDetailProductFull, detail_button = false } = props

    return (
        <Fragment>
            <div className="componentDetailItems">
                <div className="detail_item">

                    {template === "CardOneCol" && <CardOneCol template={template} item={item} />} 

                    {template === "CardOneColV2" &&
                        <CardOneColV2 template={template} item={item} 
                            showDetailSearch={showDetailSearch} 
                            showDetailProductFull={showDetailProductFull} 
                            detail_button={detail_button}
                        />
                    } 
                    {template === "CardTwoCols" &&
                        <CardTwoCols template={template} item={item} 
                            showDetailSearch={showDetailSearch} 
                            showDetailProductFull={showDetailProductFull} 
                            detail_button={detail_button}
                        />
                    }
                    {template === "CardTwoColsV2" &&
                        <CardTwoColsV2 template={template} item={item} 
                            detailProduct={item.details.detailProduct}
                        />
                    }  
                    {template === "CardOneColV3" &&
                        <CardOneColV3 template={template} item={item} 
                            showDetailSearch={showDetailSearch} 
                            showDetailProductFull={showDetailProductFull} 
                            detail_button={detail_button}
                        />
                    }  
                </div>
            </div>  
        </Fragment>
        
    )
}
