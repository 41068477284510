import React from 'react';

export default function Image(props) {

    try { 
        let imgUrl = require(`./../../assets/images/${props.img}`)
        return (<img 
            src={imgUrl} 
            alt={props.alt}
            className={props.className}
            />
        )
    } catch(e) { 
        console.error(e); 
        let imgUrl = require(`./../../assets/images/image-broken.png`)
        return (<img 
            src={imgUrl} 
            alt={props.alt}
            className={props.className}
            />
        )
    }

}




