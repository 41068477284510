import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import reduxThunk from "redux-thunk";
import promise from 'redux-promise';
import reducers from "./reducers";

//(Store): Un objeto que contiene el estado actual de la aplicación. 
//La única forma de cambiar su estado es despachando acciones. 
const mystore = createStore(reducers, {},applyMiddleware(reduxThunk, promise));

ReactDOM.render(
    <Fragment>
        <Provider store={mystore}>
            <App />
        </Provider>
    </Fragment>, 
    document.getElementById('root'));
serviceWorker.unregister();
