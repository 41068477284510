import React, {Fragment} from "react"
import ContentLoader from "react-content-loader"
import { Grid, Hidden } from '@material-ui/core'
//import config from "../../../config/config.json";

//import Image from '../Image'
import CircularProgress from '@material-ui/core/CircularProgress';

export default function PreloadSection() {
    return (
        <Fragment>
            <div className="wrapper preload preload-booking PreloadSection">
                <div className="container-preload-image">
                    <div className="inner-preload-image">
                        <Grid container spacing={2}>
                            <Grid item md={12}>
                                <div className="text-center">
                                    <CircularProgress />
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                  
                <div className="main">
                    <Grid container spacing={2}>
                        <Grid item md={8} className="col_content">
                            {/* MOBILE CONTENT */}
                            <Hidden mdUp>
                                <div className="responsive">  
                                    <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                        width={385}
                                        height={200}
                                        viewBox="0 0 385 200">
                                        <rect x="0" y="0" rx="10" ry="10" width="385" height="200" />
                                    </ContentLoader>
                                </div>
                            </Hidden>
                            {/* end MOBILE CONTENT */}     

                            <Hidden smDown>
                                <div className="responsive">
                                    <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                        width={385}
                                        height={150}
                                        viewBox="0 0 385 150">
                                        <rect x="0" y="0" rx="5" ry="5" width="385" height="150" />
                                    </ContentLoader>
                                </div>
                                
                            </Hidden>
                            
                        </Grid>
                        <Hidden smDown>
                            <Grid item md={3} className="col_sidebar">
                            
                                <div className="responsive">                                    
                                    <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                        width={200}
                                        height={115}
                                        viewBox="0 0 200 115">
                                        <rect x="0" y="0" rx="5" ry="5" width="200" height="110" />
                                    </ContentLoader>

                                    <ContentLoader speed={2} title="" backgroundColor="#d1d1d1" foregroundColor="#e1e1e1" 
                                        width={200}
                                        height={60}
                                        viewBox="0 0 200 60">
                                        <rect x="0" y="0" rx="5" ry="5" width="200" height="60" />
                                    </ContentLoader>
                                </div>
                            </Grid>
                        </Hidden>
                    </Grid>
                </div>
            </div>
        </Fragment>
    )
}
