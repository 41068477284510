import React, {Fragment} from 'react'
import { Typography } from '@material-ui/core';


export default function Maintitle(props) {
    const {client, module} = props
    
    return (
        <Fragment>
            {client.dictionary[module.toLowerCase()] ? (
                <Typography className="main-title" variant="h1">
                    <span>{client.dictionary[module.toLowerCase()].title}</span>
                </Typography> ) : (
                    <Typography className="main-title" variant="h1">
                        <span>¡Estás a minutos de concretar tu compra!</span>
                    </Typography>
                )
            } 
        </Fragment>
    )
}
