import React, {Fragment} from "react"
import ContentLoader from "react-content-loader"
import { Hidden, Grid } from '@material-ui/core' 

export default function PreloadError() {
    return (
        <Fragment>
            <div className="wrapper preload PreloadError">
                <div className="preload-header">
                    <Grid container>
                        {/* MOBILE CONTENT */}
                        <Hidden mdUp>
                            {/* <img src={`${process.env.PUBLIC_URL}/logo_${client.name}.png`} alt="Logo" className={classes.logo}  /> */}
                            <ContentLoader speed={2} title="" backgroundColor="#f3f3f3" foregroundColor="#ecebeb"
                                width={120}
                                height={48}
                                viewBox="0 0 120 48" >
                                <rect x="4" y="0" rx="5" ry="5" width="60" height="48" />
                            </ContentLoader>
                        </Hidden>
                        {/* End MOBILE CONTENT */}

                        <Hidden smDown>
                            {/* <img src={`${process.env.PUBLIC_URL}/logo_${client.name}.png`} alt="Logo" className={classes.logo}  /> */}
                            <ContentLoader speed={2} title="" backgroundColor="#f3f3f3" foregroundColor="#ecebeb"
                                width={120}
                                height={70}
                                viewBox="0 0 120 70" >
                                <rect x="0" y="0" rx="5" ry="5" width="60" height="70" />
                            </ContentLoader>
                        </Hidden>
                        
                    </Grid>
                </div>
                <div className="main">
                    <Grid container spacing={2}>
                        <Grid item md={8} className="col_content">
                            {/* MOBILE CONTENT */}
                            <Hidden mdUp>
                                <div className="responsive">  
                                    <ContentLoader speed={2} title="" backgroundColor="#e8e8e8" foregroundColor="#f0f0f0" 
                                        width={360} 
                                        height={50} 
                                        viewBox="0 0 360 50" >
                                        <circle cx="20" cy="20" r="20" /> 
                                        <rect x="50" y="5" rx="15" ry="15" width="250" height="30" />
                                    </ContentLoader>

                                    <ContentLoader speed={2} title="" backgroundColor="#e8e8e8" foregroundColor="#f0f0f0" 
                                        width={385}
                                        height={200}
                                        viewBox="0 0 385 200">
                                        <rect x="0" y="0" rx="10" ry="10" width="385" height="200" />
                                    </ContentLoader>
                                </div>
                            </Hidden>
                            {/* end MOBILE CONTENT */}

                            <Hidden smDown>
                                <ContentLoader speed={2} title="" backgroundColor="#e8e8e8" foregroundColor="#f0f0f0" 
                                    width={450} 
                                    height={50} 
                                    viewBox="0 0 450 50" >
                                    <circle cx="20" cy="20" r="20" /> 
                                    <rect x="50" y="5" rx="15" ry="15" width="395" height="30" />
                                </ContentLoader>

                                <div className="responsive">
                                    <ContentLoader speed={2} title="" backgroundColor="#e8e8e8" foregroundColor="#f0f0f0" 
                                        width={385}
                                        height={85}
                                        viewBox="0 0 385 85">
                                        <rect x="0" y="0" rx="5" ry="5" width="385" height="70" />
                                    </ContentLoader>
                                </div>

                                <ContentLoader speed={2} title="" backgroundColor="#e8e8e8" foregroundColor="#f0f0f0" 
                                    width={450} 
                                    height={50} 
                                    viewBox="0 0 450 50" >
                                    <rect x="0" y="0" rx="13" ry="13" width="395" height="26" />
                                </ContentLoader>

                                <div className="responsive">
                                    <ContentLoader speed={2} title="" backgroundColor="#e8e8e8" foregroundColor="#f0f0f0" 
                                        width={200}
                                        height={80}
                                        viewBox="0 0 200 80">
                                        <rect x="0" y="0" rx="3" ry="3" width="200" height="70" />
                                    </ContentLoader>
                                </div>
                            </Hidden>
                        </Grid>
                    </Grid>
                </div>
                <div className="preload-footer"></div>
            </div>
        </Fragment>
    )
}
