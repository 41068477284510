import React, {Fragment} from 'react'
import {Grid, Typography, Icon } from '@material-ui/core';

export default function CardTwoColsV2(props) {
    const {template, item, detailProduct} = props

    const GetStars = (props)=>{
        //console.log(props)
        const stars = props.description
        return(
            <div className="category_stars"> 
            {[...Array(5)].map((e, i) => <span key={i}>
                <span className={`${i <= stars ? 'active' : ''}`}>
                    <Icon>star</Icon>
                </span>                        
            </span>)}
        </div>
        )
    }
    return (
        <div className={template}>
            <Grid container spacing={1}>
                <Grid item xs={2} md={3}>
                    <div className="detail_item_header">
                        {item.image && <img className="detail_item_image" src={item.image} alt={item.title}/>}
                    </div>
                </Grid>
                <Grid item xs={10} md={9}>
                    {item.title && <Typography className="detail_item_title" variant="h4">{item.title}</Typography>}
                    <Fragment>

                    <ul className="detail_item_list products">                                  
                        {detailProduct.map((item, i) => 
                            <li key={i} className="list_item">
                                {item.className.includes('category_stars') ? (
                                    <GetStars className={item.className} description={item.description} />
                                    ) : (
                                        <Typography component="p" className={`${item.className} ${item.icon ? 'has_icon' : ''}`}>
                                            {item.icon && <Icon>{item.icon}</Icon>} {item.title && 
                                            <span className={`${item.className.includes('strong-title') ? 'strong' : ''}`
                                            }>{item.title}</span>} {item.description}
                                        </Typography>
                                    )
                                }
                                    
                            </li>
                        )} 
                    </ul>
                </Fragment>
                </Grid>
            </Grid>     
        </div>
    )
}
