import { getPayment, getConfirmation } from "../services/PrebookingService";
/*para usar data hardcode EJ: ?demo=true&module=flights*/
const windowUrl = window.location.search;
const demo = new URLSearchParams(windowUrl).get("demo");

export const getInfoStepAction = async (ipv4,hash, nextStepAction, addToCart, currentDataStep, lastDataStep) => {
    let response = [];
    let action = "";
    let form = new Object();
 
    switch (nextStepAction) {
        case 'getPayment':
            action = "GET_PAYMENT";

            let dataForm = new Object();
      
            form = (lastDataStep.SEND_PASSENGER !== null) ? lastDataStep.SEND_PASSENGER.passengers.passengersForm : currentDataStep.passengers.passengersForm;
       
            dataForm["passengers"]    = (lastDataStep.SEND_PASSENGER !== null) ? lastDataStep.SEND_PASSENGER.passengers : currentDataStep.passengers;
            dataForm["billing"]       = (lastDataStep.SEND_PASSENGER !== null) ? lastDataStep.SEND_PASSENGER.billing : currentDataStep.billing;
            dataForm["contact"]       = (lastDataStep.SEND_PASSENGER !== null) ? lastDataStep.SEND_PASSENGER.contact : currentDataStep.contact;
            dataForm["crossell"]      = currentDataStep.crossell;
            if(lastDataStep.SEND_PASSENGER !== null){
                if(lastDataStep.SEND_PASSENGER.passengers.aditionalForm){
                    dataForm["aditionalForm"] = lastDataStep.SEND_PASSENGER.passengers.aditionalForm;
                }else{
                    dataForm["aditionalForm"] = {}
                }
            }else{
            
                if(currentDataStep.passengers.aditionalForm){
                    dataForm["aditionalForm"] = currentDataStep.passengers.aditionalForm;
                }else{
                    dataForm["aditionalForm"] = {}
                }
            }
            
            let requestPayment = {
                "hash": hash,
                "retry": (lastDataStep.SEND_PASSENGER !== null) ? lastDataStep.SEND_PASSENGER.retry : currentDataStep.retry,
                "ip":ipv4,
                "finishedStep": [{
                    "id": "1",
                    "name": "passenger",
                    "passengers": form,
                    "dataForm": dataForm,
                    "crossell": currentDataStep.crossellSelected
                }]
            };
              //console.log(JSON.stringify(requestPayment))
          
            if (demo === "true") {
                const module = new URLSearchParams(windowUrl).get("module");
                response = await import(`.././demo-request/${module}/getPayment.json`);
                response = response.default
                console.log("VERSION HARDCODE LOCAL");
             } else {
                response = await getPayment(requestPayment);
            } 

            break;

        case 'sendPassenger':
            action = "SEND_PASSENGER"
            response = currentDataStep;
            break;
        case 'showInfo':

            action = "SHOW_INFO";

            let requestConfirmation = {
                "hash": hash,
                "finishedStep": [
                    {
                        "id": "2",
                        "name": "payment",
                        "ip":ipv4,
                        "dataForm": currentDataStep.dataForm,
                        "paymentMethod": currentDataStep.payments
                    }
                ]
            }
          //  console.log(JSON.stringify(requestConfirmation))
            if (demo === "true") {
                const module = new URLSearchParams(windowUrl).get("module");
                response = await import(`.././demo-request/${module}/getConfirmation.json`);
                response = response.default
                console.log("VERSION HARDCODE LOCAL");
            } else {
                response = await getConfirmation(requestConfirmation);
            } 

            break;
        default:
            action = "GET_UPDATE_INFO"
            break;
    }

    return {
        type: action,
        payload: response,
        addToCart: addToCart
    }
}

export const addAction = async (slider, cardProduct, purchase) => {
  
    let totalWithFormat = parseInt(purchase.totalAmountReal) + parseInt(cardProduct.priceDetail.amountReal);
    let total = totalWithFormat.toLocaleString();
    purchase.totalAmount      =  total.replace(",",".")
    purchase.totalAmountReal  =  totalWithFormat;
    purchase.priceDetail.push(cardProduct.priceDetail);
    

    return {
        type: "ADD_TO_CART",
        payload: purchase.priceDetail,
        purchase: purchase,
        cardProduct: cardProduct,
        slider:slider
    }
}

export const removeAction = async (cardProduct, purchase) => {

   
    let purchaseModificado = purchase.priceDetail.filter(element => element !== cardProduct.priceDetail)
    purchase.priceDetail = purchaseModificado;
  
    let totalWithFormat= parseInt(purchase.totalAmountReal) - parseInt(cardProduct.priceDetail.amountReal);
    let total = totalWithFormat.toLocaleString();
    purchase.totalAmount      =  total.replace(",",".")
    purchase.totalAmountReal = totalWithFormat;
   
    return {
        type: "REMOVE_TO_CART",
        payload: purchase.priceDetail,
        purchase: purchase,
        cardProduct: cardProduct
    }
}

export const updateAction = async (bank, creditCard, paymentFee, allowFormSelected) => {
   
    return {
        type: "UPDATE_PURCHASE",
        bank: bank,
        creditCard: creditCard,
        paymentFee: paymentFee,
        "allowFormSelected": allowFormSelected

    }
}

export const restartPaymentAction = () => {

    return {
        type: "RESTART_PAYMENT"
    }
}

export const updatePreloadAction = (boolean) => {
    return {
        type: "UPDATE_PRELOAD",
        preload:boolean
    }
}

export const updatePreloadRetryAction = (boolean) => {
    return {
        type: "UPDATE_PRELOAD",
        preload:boolean
    }
}

