import React, { Component, Fragment } from 'react'
import { Paper, Typography, Icon } from '@material-ui/core'
import PopoverSimpleTitleContent from "./layout/PopoverSimpleTitleContent";
import DialogTimer from './layout/DialogTimer';

export default class Timer extends Component {
    state = {
        //totalMinutes: this.props.timer.value,
        minutes: this.props.timer.value,
        seconds: 0,
    }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            }
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }
    getMessages = (timer) => {
        if (timer.messages) {
            const messages = timer.messages
            return (
                <Fragment>
                {messages.map((message, x) => 
                    <Fragment key={x}>
                        {message.items.map((item, x) => 
                            <Fragment key={x}>                                
                                <span className="icon_right">
                                    <PopoverSimpleTitleContent
                                        className="info_timer"
                                        name="info_timer"
                                        title={item.title}
                                        content={item.description}
                                        icon="info_outline"
                                    />
                                </span>
                            </Fragment>
                        )}
                    </Fragment>
                )}</Fragment>
            )
        }
    }

    render() {
        const { /* totalMinutes,  */minutes, seconds } = this.state
        const {layoutTimer, timer, expired, module } = this.props;

        


        return (
            <Fragment>{layoutTimer[module] &&
                <Paper className={`paper timer ${layoutTimer.template}`}>
                    <span className="icon_left"><Icon>access_time</Icon></span>
                    {/* <GetMessages /> */}
                    {this.getMessages(timer)}
                    <div>
                        {(minutes === 0 && seconds === 0) || expired
                            ? <DialogTimer redirect={timer.redirect} info={timer.infoModal} />
                            : <div className="text"><Typography variant="body2"><span className="inner-text">La reserva caducará en: </span><span className="time">{minutes}:{seconds < 10 ? `0${seconds}` : seconds}</span></Typography></div>
                        }
                    </div>
                </Paper>
            }</Fragment>
        )
    }
}
