import axios from "axios";
import config from "../config/config.json"

export const getPrebooking = async (request) => {
  const headers = {
    'Content-Type': "application/json",
    'token': config.client.token
    };


  const url = 'https://qfxt620jf7.execute-api.us-east-1.amazonaws.com/prod/initSteps'

   const response = await axios.post(url, JSON.stringify(request), {headers:headers})
    .then(res => {
      //console.log(res);
      return res.data;
    })
    .catch((err) => {
      //console.log(err);
      return false;
    }); 

  return response;
}

export const getPayment =  async  (request) => {


  const url = 'https://qfxt620jf7.execute-api.us-east-1.amazonaws.com/prod/initStepPayment';

  const headers ={
    'content-type': "application/json",
    'token': config.client.token
  };
  const response =  await axios.post(url,JSON.stringify(request),{headers:headers})
  .then(res => {
    //console.log(res);
    return res.data;
  })
  .catch((err) => {
    //console.log(err);
    return false;
  }); 

  return response;
  
}

export const getConfirmation = async (request) => {
  const headers ={
    'Content-Type': "application/json",
    "token": config.client.token

  };

  const url = 'https://qfxt620jf7.execute-api.us-east-1.amazonaws.com/prod/initStepConfirmation';

  const response = await axios.post(url, JSON.stringify(request),{headers:headers})
    .then(res => {
      //console.log(res);
      return res.data;
    })
    .catch((err) => {
      //console.log(err);
      return false;
    });

  return response;
}

export const getBooking = async (request) => {
  const headers ={
    'Content-Type': "application/json",
    "token": config.client.token

  };

   //	const url = 'https://y43e3jv1u1.execute-api.us-east-1.amazonaws.com/dev/lastStep';
   const url = 'https://apigw-api-checkout.api-services-group.com/api-checkout/prod/lastStep';

   	const response = await axios.post(url, JSON.stringify(request),{headers:headers})
   	.then(res => {
     	//console.log(res);
     	return res.data;
   	})
   	.catch((err) => {
     	//console.log(err);
     	return false;
   	});

  	return response;
}
