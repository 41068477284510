import React, { Component, Fragment } from 'react'
import { Card, CardHeader, CardContent, Typography, Avatar, Icon, Grid, Badge, Hidden } from '@material-ui/core';
import DetailDialog from "./layout/purchase/DetailDialog";
import InfoPopover from "./layout/InfoPopover";

export default class PurchaseDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            purchaseDetail: ''
        }
    }

    render() {
        const purchaseDetail = (Object.keys(this.props.installment).length > 0) ? this.props.installment.purchaseDetail : this.props.purchaseDetail
        const addToCart = this.props.addToCart
        const products = this.props.products
        const selectedData = this.props.selectedData
        const installment = this.props.installment
        const badge = purchaseDetail.priceDetail.filter(element=>element.addToCart === true);
      
        return (
            <div className="purchase_detail">
                <Card className="purchase_card card_primary_color">
                    <CardHeader
                        avatar={
                            <Avatar aria-label={purchaseDetail.title} className="avatar_purchase_detail">
                                <Badge badgeContent={badge.length} color="primary">
                                    <Icon>{purchaseDetail.icon}</Icon>
                                </Badge>
                            </Avatar>
                        }
                        /* Open Modal Info */
                        action={<DetailDialog
                            installment={installment}
                            selectedData={selectedData}
                            icon="expand_more"
                            purchaseDetail={purchaseDetail}
                            products={products} 
                            badgeContent={badge.length}
                            />}
                        /* Modal */
                        title={<Typography className="title_card_header" variant="h3">{purchaseDetail.title}</Typography>}
                        subheader={<Typography noWrap className="title_card_subheader" variant="body2">{purchaseDetail.subtitle}</Typography>}
                    />

                    <Hidden smDown>
                        <CardContent>
                            {addToCart.map((item, i) => {
                                return (
                                    <Fragment key={i}>
                                        <Grid container className={`item_resume ${item.className = ''} template-${item.template}`} >
                                            <Grid item xs={8}>
                                                <div className="container_item_resume">
                                                    <Typography variant="body2">
                                                        <span>{item.title}</span>
                                                        {item.charge.length > 0 &&
                                                            <InfoPopover
                                                                className="info_charge"
                                                                charge={item.charge}
                                                                title="Cargos"
                                                                icon={item.icon}
                                                                currency={purchaseDetail.currency}
                                                            />
                                                        }                                                        
                                                     </Typography>
                                                </div>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <div className={`text-right`}>
                                                    <span>{purchaseDetail.currency} {item.amount}</span>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                )
                            })}
                        </CardContent>
                    </Hidden>
                    <div className="card_footer">

                        <Grid container>
                            <Grid item xs={7}><Typography className="uppercase strong">{purchaseDetail.totalLabel}</Typography></Grid>
                            <Grid item xs={5}>
                                <Typography className="text-right amount">
                                    <span>{purchaseDetail.currency}</span> {
                                        Object.keys(installment).length !== 0 ? (installment.purchaseDetail.totalAmount) : (purchaseDetail.totalAmount)
                                    }
                                </Typography>
                            </Grid>
                        </Grid>

                        {Object.keys(installment).length !== 0 && (
                            <Hidden smDown>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Typography className="installment-amount">
                                            <span>{installment.purchaseDetail.installmentAmount}</span>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Hidden>
                        )}

                    </div>

                </Card>
            </div>
        )
    }
}