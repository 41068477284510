import React from 'react'
import Link from '@material-ui/core/Link';
import Image from './Image'

export default function Certificates(props) {
    const {client} = props

    return (
        <div className="logos-certificates">
            {client.certificates.map((item, i) => 
                <span key={i} className="logo-footer">{item.url ? (
                    <Link href={item.url} target="_blank">
                        <Image img={`${client.name}/${item.name}.png`} alt={item.name} />
                    </Link>
                ) : (
                    <Image img={`${client.name}/${item.name}.png`} alt={item.name} />
                )}</span>
            )}
        </div>  
    )
}
