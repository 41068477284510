import { getPrebooking, getBooking } from "../services/PrebookingService";
/*para usar data hardcode EJ: ?demo=true&module=flights*/
const windowUrl = window.location.search;
const demo = new URLSearchParams(windowUrl).get("demo");
const publicIp = require('public-ip');
export const getPrebookingAction = async (hash) => {
    let response = null;
 
    const ipv4 = await publicIp.v4() || "";
  
    const request = {
        "hash": hash,
        "ip":ipv4
    }
    //console.log(JSON.stringify(request))
    if (demo === "true") {
        const module = new URLSearchParams(windowUrl).get("module");
        response = await import(`.././demo-request/${module}/getPrebooking.json`);
        response = response.default
        console.log("VERSION HARDCODE LOCAL");
    } else {
        response = await getPrebooking(request);
    }

    return {
        type: "GET_PREBOOKING",
        payload: response,
        hash: hash,
        ip:ipv4
    }
}

export const getBookingAction = async (ipv4,hash, data) => {

    let response = null;
   
    const request = {
        "hash": hash,
        "finishedStep": [
            {
                "id": "3",
                "name": "confirmation",
                "ip":ipv4,
                "dataForm": data
            }
        ]
    }
    //console.log(JSON.stringify(request))
    if (demo === "true") {
        const module = new URLSearchParams(windowUrl).get("module");
        response = await import(`.././demo-request/${module}/getBooking.json`);
        response = response.default
        //console.log("VERSION HARDCODE LOCAL");
    } else {
        response = await getBooking(request);
    }


    return {
        type: "GET_BOOKING",
        payload: response
    }
}

export const updatePreloadLayoutAction = (boolean) => {
    return {
        type: "UPDATE_PRELOAD_LAYOUT"
    }
}

export const rebootAllowFinalStepAction = (boolean) => {
    return {
        type: "REBOOT_ALLOW_FINAL_STEP",
        boolean:boolean
    }
}
