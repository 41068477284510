import React, { Fragment } from 'react';
import { Paper, Icon, Typography } from '@material-ui/core';
import Image from './layout/Image'
import RawMarkup from './layout/RawMarkup'
import config from "./../config/config.json";

const GetItemsList = (props) => {
    const {category} = props
    const itemsList = props.items.map((item, i) =>             
        <li key={i}>
            <Typography variant="body2" className={`${item.className}`} >
             {category === 'restrictions' ? (
                    <Fragment><Image img={`${config.client.name}/baggage/${item.icon}.png`} alt={item.icon} /> {item.title} <RawMarkup content={item.description} /></Fragment>
                ) : (
                    <Fragment>{item.icon && <Icon>{item.icon}</Icon>} {item.title} <RawMarkup content={item.description} /></Fragment>
                )}
            </Typography>
        </li>        
    )
    return (<Fragment><ul>{itemsList}</ul></Fragment>)        
}

/* TEMPLATE MENSAJES*/
const GetMessages = (props) => {
    const {advice} = props
    return(
        <Paper className={`paper advice messages_advice advice-${advice.category} advice-${advice.template} `} >
            {advice.icon ? (
                <Fragment>
                    {advice.category === 'restrictions' ? (
                        <div className={`advice_content_${advice.category}`}>
                            <GetItemsList items={advice.items} category={advice.category} client={advice.client} />
                        </div>                            
                    ) : (
                        <Fragment>
                            <div className="advice_icon">
                                <Icon>{advice.icon}</Icon>
                            </div>
                            <div className="advice_content">
                                <GetItemsList items={advice.items} category={advice.category} client={advice.client} />  
                            </div>
                        </Fragment>
                    )}
                </Fragment>
            ) : (
                <div className={`advice_content_${advice.category}`}>
                    <GetItemsList items={advice.items} category={advice.category} client={advice.client}/> 
                </div>                    
            )}
        </Paper>  
    )
}
/* TEMPLATE MENSAJES */

/* TEMPLATE MENSAJES SIN CONTENEDOR PAPER */
const GetMessagesInner = (props) => {
    const {advice} = props
    return(
        <div className={`advice messages_advice advice-${advice.category} advice-${advice.template} `} >
            {advice.icon ? (
                <Fragment>
                    <Fragment>
                        <div className="advice_icon">
                            <Icon>{advice.icon}</Icon>
                        </div>
                        <div className="advice_content">
                            <GetItemsList items={advice.items} category={advice.category} client={advice.client} />  
                        </div>
                    </Fragment>
                </Fragment>
            ) : (
                <div className={`advice_content_${advice.category}`}>
                    <GetItemsList items={advice.items} category={advice.category} client={advice.client}/> 
                </div>                    
            )}
        </div>  
    )
}
/* TEMPLATE MENSAJES SIN CONTENEDOR PAPER */

/* MENSAJES DETALLES DEL PRODUCTO - MODAL */

export function GetMessagesDetailBottom (props){
    const {messages} = props
    return(
        <Fragment>
               {messages &&
                messages.map((advice, i) =>
                <Fragment key={i}>
                    {advice.template === 'messageDetailBottom' &&
                    <GetMessages advice={advice} />}
                </Fragment>
            )}         
        </Fragment>
    )
}

export function GetMessagesDetailTop (props) {
    const {messages} = props
    return(
        <Fragment>
            {messages &&
                messages.map((advice, i) =>
                <Fragment key={i}>
                    {advice.template === 'messageDetailTop' &&
                    <GetMessages advice={advice} />}
                </Fragment>
            )}         
        </Fragment>
    )
}

export function GetMessageDetailTopAlert (props) {
    const {messages} = props
    /* console.log(messages) */
    return(
        <Fragment>
            {messages &&
                messages.map((advice, i) =>
                    <Fragment key={i}>
                    {advice.template === 'messageDetailTopAlert' &&
                    <GetMessages advice={advice} />}
                </Fragment>
            )}         
        </Fragment>
    )
}

export function GetMessageConditions (props) {
    const {messages} = props
    //console.log(messages)
    return(
        <Fragment>
            {messages &&
                messages.map((advice, i) =>
                    <Fragment key={i}>
                        {advice.template === 'messageConditions' && 
                            <ul className="messageConditions">{advice.items.map((item, i) =>             
                                    <li key={i}>
                                        <Typography variant="body2" className={item.className}>
                                            <Icon>{item.icon}</Icon> {item.title} <RawMarkup content={item.description} />
                                        </Typography>
                                    </li>        
                                )}
                            </ul>}
                    </Fragment>
            )}         
        </Fragment>
    )
}

/* MENSAJES DETALLES DEL PRODUCTO - MODAL */

/* MENSAJES SIDEBAR */
export function GetMessagesTopSidebar (props) {
    const {messages} = props
    return(
        <Fragment>
            {messages &&
                messages.map((advice, i) =>
                <Fragment key={i}>
                    {advice.template === 'messageTopSidebar' &&
                    <GetMessages advice={advice} />}
                </Fragment>
            )}         
        </Fragment>
    )
}
/* MENSAJES SIDEBAR */

/* MENSAJES CONTENIDO PPAL */
export function GetMessageInfo (props) {
    const {messages} = props
    return( <Fragment> {messages && messages.map((advice, i) =>
        <Fragment key={i}>
            {advice.template === 'messageInfo' && 
            <Fragment>
                {/* {advice.category === 'restrictions' 
                ? (<GetMessages advice={advice} />)
                : (<GetMessagesInner advice={advice} />) } */}
                <GetMessages advice={advice} />
            </Fragment>}
            </Fragment> )}         
    </Fragment>)}

export function GetMessageRestrictions (props) {
    const {messages} = props
    return(<Fragment> {messages && messages.map((advice, i) =>
        <Fragment key={i}>
            {advice.category === 'restrictions' &&
            <GetMessages advice={advice} />}
        </Fragment> )}         
    </Fragment>)}
/* MENSAJES CONTENIDO PPAL */

/* MENSAJES PASAJEROS */
export function GetMessageInfoPasajeros (props) {
    const {messages} = props
    return(<Fragment> {messages && messages.map((advice, i) =>
        <Fragment key={i}>
            {advice.template === 'messageInfoPassengers' &&
            <GetMessagesInner advice={advice} />}
        </Fragment> )}         
    </Fragment>)}
/* MENSAJES PASAJEROS */