import React, {Fragment} from 'react'
import Icon from '@material-ui/core/Icon'
import { Paper, Typography } from '@material-ui/core';

import Image from './Image'
import Certificates from "./Certificates";

export default function SecureSite(props) {

    const {client} = props    
    return (
        <Fragment>
            <Paper className="paper secure_site">
                <div className="icon_secure_site">
                    <div className="avatar"> <Icon>verified_user</Icon></div>               
                </div>
                <div className="text_secure_site">
                    <Typography component="p">
                        <strong>{client.nameCopy} es un sitio seguro.</strong><br/>
                        Utilizamos conexiones seguras para proteger tu información.
                    </Typography>
                    <Image className="logos-sitio-seguro" img={`${client.name}/logos-sitio-seguro.png`} alt="Sitio seguro" />
                </div>
            </Paper> 

            <Certificates client={client} />
        </Fragment>
        
    )
}
