//PADRE DetailDialog
import React, { Component, Fragment } from 'react';
import { Card, CardHeader, CardContent, Typography, Grid} from '@material-ui/core';
import DetailItems from "./layout/cardDetails/DetailItems" 
import {IconCardDetail} from './layout/_IconMdi'

import {GetMessagesDetailBottom, GetMessageDetailTopAlert, GetMessagesDetailTop, GetMessageConditions} from './CardDetailMessages'

export default class CardDetail extends Component {
    render() {

        const { cardDetail:{ template, icon, title, providerImage, provider, name, secondLine, image, category, detailItems, promoLabel, messages } } = this.props

        return (
            <Fragment>

                <GetMessageDetailTopAlert messages={messages} />

                <GetMessagesDetailTop messages={messages} />
                
                <Card className="card_detail">
                    <CardHeader className="card_detail_header"
                        avatar={<IconCardDetail icon={icon} /> }
                        title={<Typography variant="h3">{title}</Typography>}
                    />                    
                    
                    <CardContent>
                        <div className={`card_detail_product card_detail_${template}`}>
                            {promoLabel &&
                                <div className="promo_label">
                                    <div className="content_label">
                                        <div className="text">{promoLabel}</div>
                                        <div className="triangle"></div>
                                    </div>
                                </div>
                            }
                            <div className="card_detail_product_content">  
                            
                                {template !== "CardTwoColsV2" && (
                                    <Fragment>
                                        {template === "CardOneColV2" ? (
                                            <Grid container spacing={1} className="CardOneColV2 autos">
                                                <Grid item sm={4}>
                                                    {image && (<div className="img_product"><img src={image} alt={name}/></div>)}  
                                                </Grid>
                                                <Grid item sm={8}>
                                                    {providerImage && (<div className="img_provider"><img src={providerImage} alt={provider}/></div>)}  
                                                    {category && (<Typography className="product_card_category" variant="body2" component="p">{category}</Typography>)}
                                                    {name && (<Typography noWrap variant="h3" className="product_card_title">{name}</Typography>)}  
                                                    {secondLine && (<Typography noWrap component="p" variant="body2" className="product_card_subtitle">{secondLine}</Typography>)} 
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <Fragment>
                                                {providerImage && (<div className="img_provider"><img src={providerImage} alt={provider}/></div>)}  
                                                {name && (<Typography noWrap variant="h3" className="product_card_title">{name}</Typography>)}  
                                                {secondLine && (<Typography noWrap component="p" variant="body2" className="product_card_subtitle">{secondLine}</Typography>)} 
                                            </Fragment>
                                        )}
                                    
                                    </Fragment>
                                )}     
                                
                                {detailItems.map((item, i) => <DetailItems key={i} 
                                    item={item} 
                                    template={template}
                                    showDetailSearch
                                    showDetailProductFull
                                /> )} 

                                <GetMessageConditions messages={messages} />
                                 
                            </div>
                        </div>                  
                    </CardContent>
                </Card>

                <GetMessagesDetailBottom messages={messages} />

            </Fragment>
        );
    }
}

