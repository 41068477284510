// parent Steps.js
import React, {Fragment} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {Dialog, IconButton, Typography, Icon, Grid, Badge } from '@material-ui/core';

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import InfoPopover from "./../InfoPopover";
import CardDetail from "../../CardDetail";

import {Card, CardHeader, Avatar } from '@material-ui/core';

const styles = theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500], 
  },
});

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <div>{children}</div>
        {onClose ? (
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <Icon>close</Icon>
            </IconButton>
        ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function DetailDialog(props) {
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => setOpen(true)  
  const handleClose = () => setOpen(false)
  const selectedDataTotal = []; 
  const {purchaseDetail, products, icon, selectedData, installment, badgeContent} = props;
  
  selectedDataTotal.push(products.pivot[0]);
 
  if(Object.keys(selectedData).length !== 0){
    selectedDataTotal.push(selectedData);
  }
 
  const GetDetailPrice = () => {
    return (
      <div className="detail_resume">
        {purchaseDetail.priceDetail.map((item, i) => {
            return (
                <Fragment key={i}>
                    <Grid container className={`item_resume template-${item.template} ${item.className && item.className !== '' && item.className}`}  >
                        <Grid item xs={8}>
                            <div> 
                                <span>{item.title}</span>
                                {item.charge.length > 0 &&
                                  <InfoPopover 
                                    className="info_charge" 
                                    charge={item.charge} 
                                    title="Cargos" 
                                    icon={item.icon} 
                                    currency={purchaseDetail.currency} 
                                />
                                }
                                
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                            <div className="text-right">
                                <span>{purchaseDetail.currency} {item.amount}</span>                                                    
                            </div>
                        </Grid>
                    </Grid>
                </Fragment>
            );
        })} 
        <div className="item_resume_price">
        <Grid container>
          <Grid item xs={6}><Typography className="uppercase strong">Total</Typography></Grid>
          <Grid item xs={6}>
              <Typography className="text-right amount">
                  <span>{purchaseDetail.currency}</span> {
                      Object.keys(installment).length !== 0 ? (installment.purchaseDetail.totalAmount) : (purchaseDetail.totalAmount)
                  }
              </Typography>
          </Grid>
        </Grid>

        {Object.keys(installment).length !== 0 && (
          <Grid container>
              <Grid item xs={12}>
                  <Typography className="installment-amount">
                      <span>{installment.purchaseDetail.installmentAmount}</span>
                  </Typography>
              </Grid>
          </Grid>
        )}
        {/* {Object.keys(installment).length !== 0 ? (
          <Fragment>
            <Grid container>
                <Grid item xs={6}><Typography className="uppercase">Total</Typography></Grid>
                <Grid item xs={6}>
                  <Typography className="text-right amount">
                    <span>{purchaseDetail.currency}</span> {installment.total_amount}
                  </Typography></Grid>
                  <Grid item xs={12}>
                    <Typography className="installment-amount">
                        <span>{installment.installment_amount}</span>
                    </Typography>
                </Grid>
            </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <Grid container>
                <Grid item xs={6}><Typography className="uppercase">Total</Typography></Grid>
                <Grid item xs={6}>
                  <Typography className="text-right amount">
                    <span>{purchaseDetail.currency}</span> {purchaseDetail.totalAmount}
                  </Typography></Grid>
            </Grid>
            </Fragment>
          )} */}
        </div>
      </div>
    )
  }

  return (
    <Fragment>

      {/* ICONO ABRIR MODAL */}
        <IconButton onClick={handleClickOpen}>
          <Icon className={icon}>{icon}</Icon>
        </IconButton>
        {/* ICONO ABRIR MODAL */}
        
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} className="modal_detail_purchase">
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            <Card className="purchase_card_modal card_primary_color">
              <CardHeader
                  avatar={
                  <Avatar className="title_card_avatar" aria-label="recipe">
                    <Badge badgeContent={badgeContent} color="primary">
                        <Icon>{purchaseDetail.icon}</Icon>
                    </Badge>
                  </Avatar>
                  }
                  title={<Typography className="title_card_header" variant="h3">{purchaseDetail.title}</Typography>}
                  subheader={<Typography className="title_card_subheader" variant="body2">{purchaseDetail.subtitle}</Typography>}
              />
            </Card>           
          </DialogTitle>          
          <DialogContent dividers className="dialog_content_resume">
            <GetDetailPrice />           
            <div className="details_products">
               {selectedDataTotal.map((product, j) => <CardDetail key={j} 
               cardDetail={product.cardDetail} />)} 
            </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
