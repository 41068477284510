import React, {Fragment} from 'react'
import {Helmet} from "react-helmet";

export default function Metas(props) {
    const {client, module} = props
    return (
        <Fragment>
            {/* <Helmet>
                <link rel="icon" href={client.faviconUrl} />
            </Helmet> */}
            {client.metas[module.toLowerCase()] &&
                <Helmet>
                    <title>{client.metas[module.toLowerCase()].title}</title>
                    <meta name="description" content={client.metas[module.toLowerCase()].description} />
                </Helmet>
            } 
        </Fragment>
    )
}
