import React, { Component, Suspense } from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import config from ".././config/config.json";

import PreloadError from "./layout/preloads/PreloadError";

class ErrorViewHandler extends Component {

    /* constructor(props) {
        super(props);
    } */

    getComponent() {
       
        let containerModule = (Object.keys(this.props.layoutReducer.prebooking).length > 0) ? this.props.layoutReducer.prebooking.step.nameContainer : this.props.stepReducer.dataStep.GET_PAYMENT.step.nameContainer;
        let prebooking = (Object.keys(this.props.layoutReducer.prebooking).length > 0) ? this.props.layoutReducer.prebooking : this.props.stepReducer.dataStep.GET_PAYMENT;
        
        let Component = React.lazy(() => import(`./layout/errors/${containerModule}`));
        return (<Suspense
            fallback={<PreloadError />} >
            <Component prebooking={prebooking} />
        </Suspense>)
    }

    render() {
       
        let path = (Object.keys(this.props.layoutReducer.prebooking).length > 0) ? this.props.layoutReducer.prebooking.step.path : this.props.stepReducer.dataStep.GET_PAYMENT.step.path;

        return (
            (Object.keys(this.props.layoutReducer.prebooking).length > 0 || Object.keys(this.props.stepReducer.dataStep.GET_PAYMENT).length > 0) ?
                (this.props.pathConfig === path) ?
                    this.getComponent() :
                    <Redirect to={config.defaultErrorRoute} exact />
            :
                <Redirect to={config.defaultErrorRoute} exact />
        );
    }
}
const mapStateToProps = reducers => {
    return reducers;
};
export default connect(
    mapStateToProps,
    null,
)(ErrorViewHandler);