import React, {Fragment} from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {Popover, Typography, IconButton, Icon} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(2),
      maxWidth: '320px'
    },
  }));

export default function PopoverSimpleTitleContent(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    const {name, id, icon, title, content} = props

    return (
        <Fragment>
        <IconButton 
            className={name} 
            aria-label={content} 
            aria-owns={open ? id : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
        >
          <Icon className={icon}>{icon}</Icon>
        </IconButton>
        <Popover
            id={id}
            className={classes.popover}
            classes={{paper: classes.paper}}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
            }}
            transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            <Fragment>
                <Typography variant="body1" className="mb-05">{title}</Typography>
                <Typography variant="body2">{content}</Typography>
            
            {/* <pre>
                {JSON.stringify(props, null, 2) }
            </pre> */}
            </Fragment>
        </Popover>
    </Fragment>
    )
}
