import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Image from './layout/Image'
import Link from '@material-ui/core/Link';
import Chip from '@material-ui/core/Chip';
import { Icon } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    site_header: {
        '& img' : {
            [theme.breakpoints.down('sm')]: {
                maxHeight: '40px'
            }
        }
    }
}))

export default function Header(props) {
    const classes = useStyles();
    const {client, userLogin } = props
    
    return (
        <Fragment>
            <AppBar position="relative">
                <Toolbar className={classes.site_header}>
                    <Typography variant="h6">
                        <Link href={client.siteUrl} target="_blank">
                            <Image img={`${client.name}/logo.png`} alt={client.nameCopy} className={classes.logo} />
                        </Link>                        
                    </Typography>                    
                    {userLogin && userLogin !== undefined && userLogin !== "" && 
                        <Chip variant="outlined"
                            className="name_user"
                            icon={<Icon> account_circle </Icon>}
                            label={<span>Hola, <span className="name">{userLogin}</span></span> }
                        />
                    }
                </Toolbar>
            </AppBar>
        </Fragment>
    )
}
