import React, { Component, Fragment } from 'react';
import Header from "./Header";
import FooterFinalView from "./FooterFinalView";
import Theme from "../assets/Theme";
import config from ".././config/config.json";
import { Typography, Grid, Button, Container, Hidden } from '@material-ui/core'
import imgError from './../assets/images/img-error.svg'

export default class Layout extends Component {

    /* constructor(props) {
        super(props);
    } */

    render() {
        const messages = config.error404.messages
        const stylesConfig = config.styles
        return (
            <Fragment>
                <Theme>
                <div className="wrapper flex_content" styles={stylesConfig}>
                    <Header className="flex_content_header" client={config.client}/>
                    <div className="main flex_content_body">
                        <section className="errorPage">
                            <Container>
                                <Grid container spacing={2}>
                                    <Grid item sm={7}>
                                        {messages.map((item, i) => <Fragment key={i}>
                                            {item.position === "titleError" &&
                                                item.message.map((item, i) => {
                                                    return (
                                                        <div className={`header_step`} key={i}>
                                                            <Typography variant="h1" component="h2" gutterBottom className="title_step">
                                                                <span className="text">{item.value}</span>
                                                            </Typography>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </Fragment>)}
                                        <Hidden smUp>
                                            <img className="img-error" src={imgError} alt="Página no encontrada" />
                                        </Hidden>

                                        {messages.map((item, i) => <Fragment key={i}>
                                            {item.position === "descriptionError" &&
                                                item.message.map((item, i) => {
                                                    return (
                                                        <Typography key={i} className="description">{item.value}</Typography>
                                                    )
                                                })
                                            }
                                        </Fragment>)}
                                        <Button href={config.error404.buttonAction} size="small" color="primary" variant="contained" className="mb-2">
                                            {config.error404.buttonCopy}
                                        </Button>
                                    </Grid>
                                    <Hidden xsDown>
                                        <Grid item sm={5}>
                                            <img className="img-error" src={imgError} alt="Página no encontrada" />
                                        </Grid>
                                    </Hidden>
                                </Grid>
                                
                                
                            </Container>
                        </section>
                    </div>
                    <FooterFinalView className="flex_content_footer" client={config.client}/>
                </div>
                </Theme>
            </Fragment>
        )
    }
}