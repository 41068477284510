import React, { Component, Fragment } from 'react';
import Header from "./Header";
import Footer from "./Footer";
import FooterFinalView from "./FooterFinalView";
import Steps from "./Steps";
import Preload from "./layout/preloads/Preload";
import PreloadBooking from "./layout/preloads/PreloadBooking";
import { connect } from "react-redux";
import { getPrebookingAction, getBookingAction,updatePreloadLayoutAction } from "../actions/LayoutAction";
import Theme from "../assets/Theme";
import config from ".././config/config.json";
import { Redirect } from "react-router-dom";
import { GetProductPrebooking } from '../services/GetProducts'
class Layout extends Component {

    constructor(props) {
        super(props);
        /* console.log(this.props) */

        this.state = {
            hash: '',
            "finalStepPreload": false,
        }
        window.myvar = []

        this.handlerFinalStep = this.handlerFinalStep.bind(this);
    }

    UNSAFE_componentWillMount() {
        const hash = this.props.match.params.hash;
        this.setState({ hash: hash,prebooking:this.props.prebooking });
        this.props.dispatchGetPrebookingAction(hash);
    }

    handlerFinalStep(dataStep) {
        //console.log("handlerFinalStep layout");
        this.props.updatePreloadLayoutAction(true);
        this.props.dispatchGetBookingAction(this.props.ip,this.state.hash, dataStep);
        this.setState({ "finalStepPreload": true })

    }

    render() {
        //console.log("layout props", this.props);
        const allowFinalStep = this.props.allowFinalStep;
        const render = this.props.startRender;
        const avalability = (this.props.prebooking.hasOwnProperty('error')) ? true : false;
       
        if (!avalability) {
            GetProductPrebooking(this.props.prebooking,this.props.location.search)
        }

        const client = config.client

        const stylesConfig = config.styles

        const CurrentPreload = () => {
            if(this.props.prebooking.preloads!== undefined && 
                this.props.prebooking.preloads.step3 !== undefined){
                return (<Preload preload={this.props.prebooking.preloads.step3} />)
            } else {
                return (<Preload preload="" />)
            }
        }

        return (

            <Fragment>
                {!this.props.preload ? (
                    (!render) ?
                    <Redirect to={config.defaultErrorRoute} exact />
                    :
                    (avalability) ?
                        <Redirect to={this.props.prebooking.step.path} />
                        :
                        (!this.props.prebooking.styles) ?
                                <Redirect to={{
                                    pathname:`${this.props.prebooking.step.path}${this.props.prebooking.step.data.idTransaction}${this.props.location.search}`,
                                    state:{ 
                                    dataFinal:this.props.prebooking,
                                    prebooking:this.props.prebooking,
                                    preload:this.props.preload ,
                                    userLogin: this.props.userLogin,
                                    ip:this.props.ip
                                }
                                }} exact />
                            :
                            <Theme>
                                <div className={`wrapper`} styles={stylesConfig} >
                                    <Header client={client} userLogin={this.props.userLogin} />
                                    <div className="main">
                                        {(allowFinalStep) ?
                                            <Fragment>
                                                {(this.props.dataFinal === false) ? 
                                                    <Redirect to={config.defaultErrorRoute} exact />
                                                :
                                                <Redirect to={{
                                                    pathname:`${this.props.dataFinal.step.path}${this.props.dataFinal.step.data.idTransaction}${this.props.location.search}`,
                                                    state:{ 
                                                    dataFinal:this.props.dataFinal,
                                                    prebooking:this.props.prebooking,
                                                    preload:this.props.preload,
                                                    userLogin: this.props.userLogin,
                                                    ip:this.props.ip
                                                }
                                                }} exact />
                                                /*   <ThankYouPage
                                                    dataFinal={this.props.dataFinal}
                                                    prebooking={this.props.prebooking}
                                                    preload={this.props.preload}     
                                                /> */}
                                            </Fragment>
                                        :
                                            <Steps
                                                getFinalStep={this.handlerFinalStep}
                                                steps={this.props.prebooking.step}
                                                crossell={this.props.prebooking.crossell}
                                                client={client}
                                                purchaseDetail={this.props.prebooking.purchaseDetail}
                                                timer={this.props.prebooking.timer}
                                                products={this.props.prebooking.products}
                                                hash={this.props.hash}
                                                expired={this.props.prebooking.expired}
                                                data={this.props.prebooking.step[0].data}
                                                layout={client.layout}
                                                module={this.props.prebooking.step[0].data.module}
                                                preloadCurrent={this.props.prebooking.preloads}
                                                ip={this.props.ip}
                                                module={this.props.match.params.module}
                                            />
                                        }
                                    </div>

                                    {(allowFinalStep) ? (<FooterFinalView client={config.client}/>) 
                                    : (<Footer client={config.client}/>)}
                                    
                                </div>
                            </Theme>
                ) : (
                    <Fragment>
                        {this.state.finalStepPreload ? (
                            <PreloadBooking preload={this.props.prebooking.preloads.step3} />
                        ): (<CurrentPreload />) } 
                    </Fragment>
                )
                }
            </Fragment>
        )
    }
}

const mapStateToProps = reducers => {
    return reducers.layoutReducer;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchGetPrebookingAction: (hash) =>
            dispatch(
                getPrebookingAction(
                    hash
                ),
            ),
        dispatchGetBookingAction: (ip,hash, dataStep) =>
            dispatch(
                getBookingAction(
                    ip,
                    hash,
                    dataStep
                   
                )
            ),
        updatePreloadLayoutAction: (boolean) =>{
            dispatch(updatePreloadLayoutAction(boolean))
        }
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Layout);
