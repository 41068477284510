import React from 'react';
import {Dialog, Typography, Button, DialogTitle, DialogContent, DialogActions} from '@material-ui/core';

export default function DialogTimer(props) {
  return (
        <Dialog open className="dialog-timer">
            <DialogTitle disableTypography className="text-center">
                <Typography variant="h3">¡Tu búsqueda ha expirado!</Typography></DialogTitle>
            <DialogContent className="text-center">
                <Typography>Volvé a los resultados de tu búsqueda de {props.info}</Typography>            
            </DialogContent>
            <DialogActions className="align-center">
                <Button  href={props.redirect} color="default" variant="contained" size="small">Volver a resultados</Button>
            </DialogActions>  
      </Dialog>
  );
}
