const INITIAL_STATE = {
  dataStep: { "SEND_PASSENGER": null, "SHOW_INFO": null, "GET_UPDATE_INFO": null, "GET_PAYMENT": null },
  preload: true,
  addToCart: [],
  historyAddToCart: '',
  selected: {},
  selectedData: {},
  purchase: [],
  bank: {},
  creditCard: {},
  paymentFee: {},
  allowFormMethodPayment: [],
  historyMethodPayment: [],
  preloadRetry: true,
  paymentSelected: ''
  
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case "GET_PAYMENT":
   
      if(action.payload.error && action.payload.error == true){
        return { ...state, preload: true,  preloadRetry:false, historyAddToCart: [], historyMethodPayment: [], allowFormMethodPayment: [], dataStep: { "GET_PAYMENT": action.payload }, addToCart: [] };
      }
      const allow = [];
      action.payload.response.step.data.payments.map((payment, i) => {
        allow[i] = { "name": payment.name, "allow": false, "allowBlock":true };

      })
    
      return { ...state, preload: true,  preloadRetry:false, historyAddToCart: action.addToCart, historyMethodPayment: allow, allowFormMethodPayment: allow, dataStep: { "GET_PAYMENT": action.payload }, addToCart: action.addToCart };
    case "GET_UPDATE_INFO":

      return { ...state, historyAddToCart: action.addToCart, dataStep: { "GET_UPDATE_INFO": action.payload }, addToCart: action.addToCart };
    case "ADD_TO_CART":
    
     let nameCrossellSelected = `${action.cardProduct.module}Crossell`;
    // busco el modulo crossell que agrego al carrito y lo pongo en false, para que oculte el slider y muestre el resumen con form
    
      let response = Object.keys(action.slider).filter(element => element === nameCrossellSelected);
    
      action.slider[response].status = false;
      action.slider[response].resumen = action.cardProduct;

    
      return { ...state, historyAddToCart: action.payload, addToCart: action.payload, purchase: action.purchase, selected: action.slider, selectedData: action.cardProduct };
    case "REMOVE_TO_CART":
      
      let nameCrossell = `${action.cardProduct.module}Crossell`;
    // busco el modulo crossell que uito del carrito y lo pongo en true, para que vuelva a mostrar el slider
      let responseFilter = Object.keys(state.selected).filter(element => element === nameCrossell);
      state.selected[responseFilter].status = true;
      state.selected[responseFilter].resumen = {};

    
      return { ...state, addToCart: action.payload, purchase: action.purchase, selected: state.selected, selectedData: [] };
    case "UPDATE_PURCHASE":
      /* Esta opcion se ejecuta cuando el usuario selecciona un pago */
      
      const updateAllowForm = [];
     
      for (let i = 0; i < state.allowFormMethodPayment.length; i++) {

        if (state.allowFormMethodPayment[i].name === action.allowFormSelected.name) {
          updateAllowForm[i] = { "name": state.allowFormMethodPayment[i].name, "allow": true, "allowBlock":false };
         
        } else {
          updateAllowForm[i] = { "name": state.allowFormMethodPayment[i].name, "allow": false , "allowblock": false};

        }

      }
      //console.log(action.allowFormSelected.name)
      let fee =action.paymentFee.purchaseDetail.priceDetail;
      let purchaseDetail = action.paymentFee.purchaseDetail;
    
        if(action.paymentFee.purchaseDetail.purchaseDetail){
         
          purchaseDetail = action.paymentFee.purchaseDetail.purchaseDetail;
        }
      
        return { ...state, paymentSelected: action.allowFormSelected.name, addToCart: fee, purchase: purchaseDetail, allowFormMethodPayment: updateAllowForm, bank: action.bank, creditCard: action.creditCard, paymentFee: action.paymentFee };
     

    case "RESTART_PAYMENT":
      /* Esta accion se ejecuta cuando se modifica un pago */

      return { ...state, paymentFee: [], allowFormMethodPayment: state.historyMethodPayment, addToCart: state.historyAddToCart };
    case "SHOW_INFO":
    
      return { ...state, preload: true, dataStep: { "SHOW_INFO": action.payload } }
    case "SEND_PASSENGER":

      return { ...state, preload: true, historyAddToCart: action.addToCart, dataStep: { "SEND_PASSENGER": action.payload }, addToCart: action.addToCart };
    case "UPDATE_PRELOAD":
      return { ...state, addToCart: state.addToCart, preload: false  }
    case "UPDATE_PRELOAD_RETRY":
    
      return { ...state, addToCart: state.addToCart, preloadRetry:true  }
    default:
      return state;
  }
};
