import React from 'react'
import {Grid, Typography } from '@material-ui/core';
import {Icon } from '@material-ui/core';

export default function CardTwoCols(props) {
    const {template, item, showDetailSearch, showDetailProductFull, detail_button} = props

    const GetDetailSearch = (props) => {
        const detailSearch = props.detailSearch;
        return(
            <Typography component="p" className="detail_item_list mb-1">
                {detailSearch.map((item, i) => 
                    <span key={i} className="item_detail_search">
                        <strong>{item.title}</strong> {item.description} 
                    </span>
                )} 
            </Typography>
        )
    }

    const GetDetailProduct = (props) => {
        const detailProduct = props.detailProduct
        return(
            <ul className="detail_item_list products">                                  
                {detailProduct.map((item, i) => 
                    <li key={i} className="list_item">
                        <div className={`${item.className}`}>
                            <Typography component="p" className={item.icon ? 'has_icon' : ''}>
                                {item.icon && <Icon>{item.icon}</Icon> } 
                                {item.title && <strong>{item.title}</strong> }
                                {item.description}
                            </Typography>
                        </div>
                    </li>
                )} 
            </ul>
        )
    }

    const GetDetailProductSelection = (props) => {
        const detailProduct = props.detailProduct;
        const detailProductSelection = detailProduct.filter( (item, i)=> i <= 3) //Incluye item title
        return(
            <ul className="detail_item_list products">                                  
                {detailProductSelection.map((item, i) => 
                    <li key={i} className="list_item">
                        <div className={`${item.className}`}>
                            <Typography component="p" className={item.icon ? 'has_icon' : ''} noWrap>
                                {item.icon && <Icon>{item.icon}</Icon> } 
                                {item.title && <strong>{item.title}</strong> }
                                {item.description}
                            </Typography>
                        </div>
                    </li>
                )} 
            </ul>
        )
    }
    
    return (
        <div className={template}>  
            <Grid container spacing={1} >
                <Grid item xs={2} md={3}>
                    <div className="detail_item_header">
                        {item.image && <img className="detail_item_image" src={item.image} alt={item.title}/>}
                    </div>
                </Grid>
                <Grid item xs={10} md={9}>
                    {item.title && <Typography className="detail_item_title" variant="h4">{item.title}</Typography>}
                    {showDetailSearch && 
                        item.details.detailSearch &&
                        <GetDetailSearch detailSearch={item.details.detailSearch} /> 
                    }
                    {item.details.detailProduct &&
                        showDetailProductFull ? (<GetDetailProduct detailProduct={item.details.detailProduct} />) :
                        (<GetDetailProductSelection detailProduct={item.details.detailProduct} />)
                    }                                                   
                    {detail_button}  
                </Grid>
            </Grid>
        </div>
    )
}
