const INITIAL_STATE = {
	prebooking: {},
	preload: true,
	hash: '',
	dataFinal: {},
	allowFinalStep: false,
	startRender: '',
	ip:'',
	userLogin:""
};
export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case "GET_PREBOOKING":
		
			const startRender = (!action.payload) ? action.payload : true;
			return { ...state, userLogin: action.payload.userLogin, startRender: startRender, prebooking: action.payload, preload: false, hash: action.hash,ip:action.ip };
		case "GET_BOOKING":
			//console.log("booking",action.payload);
			return { ...state, dataFinal: action.payload,prebooking:state.prebooking, allowFinalStep: true, preload: false };
		case "UPDATE_PRELOAD_LAYOUT":
			return { ...state, preload: true,prebooking:state.prebooking }
		default:
			return state;
	}
};
