import React  from 'react'
import {Typography } from '@material-ui/core';
import IconC from './../_IconC'

export default function CardOneColV2(props) {
    const {template, item, showDetailSearch, showDetailProductFull, detail_button} = props

    const GetDetailSearch = (props) => {
        const detailSearch = props.detailSearch;
        return(
            <Typography component="p" className="detail_item_list mt-2">
                {detailSearch.map((item, i) => 
                    <div key={i} className="item_detail_search">
                        <strong>{item.title}</strong> {item.description} 
                    </div>
                )} 
            </Typography>
        )
    }

    const GetDetailProductSelection = (props) => {
        const detailProduct = props.detailProduct;
        //const detailProductSelection = detailProduct.filter( (item, i)=> i <= 4) //Incluye item title
        const detailProductSelection = detailProduct.filter((item, i) => item.className.includes("featured") ); //Incluye item with class "featured"

        return(
            <ul className="detail_item_list products">                                  
                {detailProductSelection.map((item, i) => 
                    <li key={i} className={`${item.className} list_item`}>
                        <Typography component="p" className={item.icon ? 'has_icon' : ''}>
                            {item.icon && <IconC icon={item.icon} className="custom_icon" />}  {item.title && <span className="title">{item.title}</span> }
                            {item.description && <span className={`${item.className.includes('rate_type') && 'ellipsisx3'} description`}>{item.description}</span>}
                        </Typography>
                    </li>
                )} 
            </ul>
        )
    }

    const GetDetailProduct = (props) => {
        const detailProduct = props.detailProduct
        return(
            <ul className="detail_item_list products">                                  
                {detailProduct.map((item, i) => 
                    <li key={i} className={`${item.className} list_item`}>
                        <Typography component="p" className={item.icon ? 'has_icon' : ''}>
                            {item.icon && <IconC icon={item.icon} className="custom_icon" />}  {item.title && <span className="title">{item.title}</span> }
                            {item.description && <span className="description">{item.description}</span>}
                        </Typography>
                    </li>
                )} 
            </ul>
        )
    }

    return (
        <div className={template}>
            {item.details.detailProduct &&
                showDetailProductFull ? (<GetDetailProduct detailProduct={item.details.detailProduct} />):
                (<GetDetailProductSelection detailProduct={item.details.detailProduct} />)
            } 
            {showDetailSearch && 
                item.details.detailSearch &&
                <GetDetailSearch detailSearch={item.details.detailSearch} /> 
            }
            {detail_button}            
        </div>
    )
}
