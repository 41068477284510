import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Layout from "./components/Layout";
import LayoutError from "./components/LayoutError";
import ErrorViewHandler from "./components/ErrorViewHandler";
import LayoutRetry from "./components/LayoutRetry";
import ThankYouPage from "./components/ThankYouPage";


import config from "./config/config.json";

class Routing extends Component {
    render() {
        return (
            <BrowserRouter>
                <Switch>
                    <Route path={"/:module/prebooking/:hash"} component={Layout} exact />
                    <Route path={"/retried/:hash"} component={LayoutRetry} />
                    <Route path={"/checkout/:module/booking/id/:idTransaction"} component={ThankYouPage} />
                    <Route path={config.defaultErrorRoute} component={LayoutError} exact />
                    {
                        config.routesError.map((routeError, i) =>
                            <Route path={routeError.path} exact key={i} >
                                <ErrorViewHandler pathConfig={routeError.path} />
                            </Route>
                        )
                    } 
                </Switch>
            </BrowserRouter>
        );
    }
}

export default Routing;