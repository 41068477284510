import React, { Component, Fragment } from 'react';
import Header from "./Header";
import FooterFinalView from "./FooterFinalView";
import Steps from "./Steps";
import Preload from "./layout/preloads/Preload";
import PreloadBooking from "./layout/preloads/PreloadBooking";
import { connect } from "react-redux";
import { getInfoStepAction,updatePreloadAction} from "../actions/StepAction";
import { getBookingAction,updatePreloadLayoutAction} from "../actions/LayoutAction";
import Theme from "../assets/Theme";
import config from ".././config/config.json";
import { Redirect } from "react-router-dom";

class LayoutRetry extends Component {

    constructor(props) {
        super(props);
        this.state ={
            hash:'',
            allowFinalStep:false,
            prebooking:[]
          
        }
     
        this.handlerFinalStep = this.handlerFinalStep.bind(this);
    }

   
    componentDidMount() {
        //console.log("componentDidMount");
        //preload de step reducer
        this.props.updatePreloadAction(true);
      
        const hash = this.props.match.params.hash;
        
        this.setState({ hash: hash,prebooking:this.props.layoutReducer.prebooking });
        let nextStepAction = 'getPayment';
        let currentDataStep = []
        let dataStep = []
        let passengers = [{
            'adults':[],
            'infants':[],
            'child':[]
        }]
        passengers['passengersForm']=[];
        currentDataStep['passengers'] = passengers;
        currentDataStep['billing'] = {};
        currentDataStep['contact'] = {};
        currentDataStep['crossell'] = [];
        currentDataStep['crossellSelected'] = [];
        currentDataStep['retry'] = 1;
        dataStep['SEND_PASSENGER'] = null;
        
        this.props.dispatchGetInfoStepAction(this.props.location.state.ip,hash, nextStepAction, [], currentDataStep, dataStep); 
    }
  
   
    handlerFinalStep(dataStep) {
        //console.log("handlerFinalStep layout retry");
        this.setState({allowFinalStep:true})
      
        // preload de layout reducer
        this.props.updatePreloadLayoutAction(true);
        this.props.dispatchGetBookingAction(this.props.layoutReducer.ip, this.state.hash, dataStep);
    }
    render() {
   
        const allowFinalStep = (this.props.location.state.allowFinalStep && this.props.location.state.allowFinalStep === false) ? this.props.location.state.allowFinalStep : this.state.allowFinalStep;
        const purchaseDetail =this.props.location.state.purchaseDetail;
        //const client =this.props.location.state.client;
        const client = config.client;
        const products =this.props.location.state.products;
        const step =this.props.location.state.step;
        const timer =this.props.location.state.timer;
        const module =this.props.location.state.module;
        //const styles =this.props.location.state.styles;
        const stylesConfig = config.styles

        const CurrentPreload = () => {
            if(this.props.layoutReducer.prebooking.preloads!== undefined && this.props.layoutReducer.prebooking.preloads.step3 !== undefined){
                return (<Preload preload={this.props.layoutReducer.prebooking.preloads.step3} />)
            } else {
                return (<Preload preload="" />)
            }
        }
       
        return (

            <Fragment>
                {this.props.stepReducer.preloadRetry  ?
                ( <CurrentPreload /> )
                : (<Theme>
                    <div className="wrapper" styles={stylesConfig} >
                        <Header client={client} />
                        <div className="main">
                            {(allowFinalStep) ?
                                <Fragment>
                                        {this.props.layoutReducer.preload  ?
                                        ( <PreloadBooking preload={this.props.layoutReducer.prebooking.preloads.step3} /> )
                                        : (
                                            (this.props.dataFinal === false) ? 
                                                <Redirect to={config.defaultErrorRoute} exact />
                                            :
                                                <Redirect to={
                                                    {
                                                        pathname:`${this.props.layoutReducer.dataFinal.step.path}${this.props.layoutReducer.dataFinal.step.data.idTransaction}`,
                                                        state:{ 
                                                        dataFinal:this.props.layoutReducer.dataFinal,
                                                        prebooking:this.props.layoutReducer.prebooking,
                                                        preload:this.props.layoutReducer.preload,
                                                        ip:this.props.location.state.ip
                                                    }
                                                    }} exact />
                                        )}
                                </Fragment>
                                
                            :(this.props.stepReducer.dataStep.GET_PAYMENT != undefined && this.props.stepReducer.dataStep.GET_PAYMENT.error ?
                            (<Redirect to={this.props.stepReducer.dataStep.GET_PAYMENT.step.path}  />)
                            :(
                                <Steps
                                    preload={false}
                                    purchaseDetail={purchaseDetail}
                                    client={client}
                                    products={products}
                                    steps={step}
                                    timer={timer}
                                    allowFormMethodPayment={this.props.stepReducer.allowFormMethodPayment}
                                    allowForm={this.props.stepReducer.allowForm}
                                    creditCard={this.props.stepReducer.creditCard}
                                    bank={this.props.stepReducer.bank}
                                    paymentFee={this.props.stepReducer.paymentFee}
                                    data={this.props.stepReducer.dataStep}
                                    getFinalStep={this.handlerFinalStep}  
                                    activeStep={1} 
                                    hash={this.state.hash}
                                    layout={client.layout}
                                    module={module} 
                                    preloadCurrent={this.props.layoutReducer.prebooking.preloads} 
                                    ip={this.props.location.state.ip}                                           
                                />
                            ) )} 
                            
                        </div>
                        <FooterFinalView client={client}/>

                    </div>
                </Theme>)
                }
               
            </Fragment>
        )
    }
}

const mapStateToProps = reducers => {
    return reducers;
};

const mapDispatchToProps = dispatch => {
    return {
        dispatchGetInfoStepAction: (ip, hash, nextStepAction, addToCart, currentDataStep, lastDataStep) =>
        dispatch(
            getInfoStepAction(
                ip, hash, nextStepAction, addToCart, currentDataStep, lastDataStep
            ),
        ),
        updatePreloadLayoutAction: (boolean) =>{
            dispatch(updatePreloadLayoutAction(boolean))
        },
        updatePreloadAction: (boolean) =>{
            dispatch(updatePreloadAction(boolean))
        },
        dispatchGetBookingAction: (ip, hash, dataStep) =>
        dispatch(
            getBookingAction(
                ip,
                hash,
                dataStep
            )
        )
    };
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LayoutRetry);