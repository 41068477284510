import React from 'react'
import {Typography, Icon, Chip } from '@material-ui/core';
import IconC from '../_IconC'

export default function CardOneCol(props) {
    const {template, item} = props

    const GetDetailSearchOneCol = (props) => {
        const detailSearch = props.detailSearch;
        return(
            <ul className="detail_item_list mb-1">
                {detailSearch.map((item, i) => 
                    <li key={i} className="list_item">
                        <div className={`${item.className}`}>
                            <Typography component="p" className={item.icon ? 'has_icon' : ''} >
                                {item.icon && <Icon>{item.icon}</Icon> } 
                                <strong>{item.title}</strong> {item.description}
                            </Typography>
                        </div>
                    </li>
                )} 
            </ul>
        )
    }

    const GetDetailProductSelectionOneCol = (props) => {
        const detailProduct = props.detailProduct;
        return (
            <ul className="detail_item_list product">
                {detailProduct.map((item, i) => 
                    <li key={i} className="list_item detail_product_item">
                        <div className={`detail_product_item_container ${item.icon ? 'has_icon' : ''} ${item.className}`}>
                            {item.icon && <IconC icon={item.icon} className="custom_icon" />} 
                            {item.title && 
                                <Typography component="p">
                                    <strong>{item.title}</strong> 
                                </Typography>
                            }
                            {item.description &&
                                <Typography component="p">
                                    {item.description}
                                </Typography>
                            }
                        </div>
                    </li> 
                )}
            </ul>
        )
    }

    return (
        <div className={template}>                                       
            <div className="detail_item_header">
                {item.title && <Typography className="detail_item_title" variant="h4">
                    {item.tagLabel && <span className="detail_item_tags">
                        <Chip icon={item.icon && <Icon>{item.icon}</Icon> } size="small" label={item.tagLabel} /></span>}
                    {item.title}
                    {item.image && <img className="detail_item_image" src={item.image} alt={item.title}/>}
                </Typography>}
                
            </div>  
            {item.details.detailSearch &&
                <GetDetailSearchOneCol detailSearch={item.details.detailSearch} />
            }
            {item.details.detailProduct &&
                <GetDetailProductSelectionOneCol detailProduct={item.details.detailProduct} />
            } 
        </div>
    )
}
